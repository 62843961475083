<template>
  <section>
    <b-row>
      <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">
        <stat
          :variant="stat.variant"
          :icon="stat.icon"
          :name="stat.name"
          :value="stat.value"
          :unit="stat.unit"
        ></stat>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader
          :dataUrl="rendimientoPromedioPorFundoChart00"
          loadingStrategy="ALWAYS"
        >
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs>
          <b-tab v-for="fundo in fundos" :title="fundo" :key="fundo" lazy>
            <fundo-predicciones-tab
              :fnd_name="fundo"
              :mdf_name="mdf_selected"
            ></fundo-predicciones-tab>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
          
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import Stat from '@/views/clients/base/components/Stat.vue'

import useApiServices from '@/services/useApiServices.js';

import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import FundoPrediccionesTab from './FundoPrediccionesTab.vue';


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Stat,

    BOverlay,
    BSpinner,

    BrunaForm,
    BrunaElementLoader,
    FundoPrediccionesTab




  },

  methods: {



    loadStats()
    {
      axios.request({
        method: "get",
        url: this.stats00Url,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
          this.stats = response.data
        })




    },



  },

  mounted()
  {


    this.loadStats();




  },




  created()
  {


  },

  watch: {

    mdf_selected: {

      handler(newValue, oldValue)
      {

        let query = qs.stringify({ mdf_name: newValue })

        this.stats00Url = useApiServices.historicoPredicciones.stats00 + "?" + query
        this.rendimientoPromedioPorFundoChart00 = useApiServices.historicoPredicciones.rendimientoPromedioPorFundoChart00 + "?" + query

        this.loadStats();


      },

    },

  

  },

  props: {
    mdf_selected: {
      type: String,
      required: false,
      default: () => null
    },

    

    fundos: {
      type: Array,
      required: false,
      default: () => []
    },


  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {



      stats: [],

      stats00Url: useApiServices.historicoPredicciones.stats00,
      rendimientoPromedioPorFundoChart00: useApiServices.historicoPredicciones.rendimientoPromedioPorFundoChart00,

      debug: debug,


    };
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
        
        
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
        
        <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
          