<template>
    <section >

  
      <b-row>
        <b-col sm="12">
          <ag-grid-table
            ref="grid-historico-runs"
            :debug="true"
            :configUrl="historicoOptimizationsConfig"
            :dataUrl="historicoOptimizationsData"
            @gridReady="onGridReady"
            @getRowsLoaded="onGetRowsLoaded"
            rowSelection="multiple"
            :rowClassRules="rowClassRules"
            :pinnedTopRowData="pinnedTopRowData"
            @selectionChanged="onSelectionChanged"
            @actionReceived="actionReceived"
            :actions="actions"
          >
            <template v-slot:actions>
              <div>
                <b-button-toolbar
                  aria-label="Toolbar with button groups and input groups"
                  justify
                >
                  <b-button-group style="margin-bottom: 1rem">
                    <b-button
                      size="sm"
                      :disabled="disableCompareRuns"
                      @click="compareRuns()"
                      variant="primary"
                      >{{ $t("Comparar") }}</b-button
                    >


                  </b-button-group>


                  <b-form-checkbox @change="showLastPredictionChange" v-model="showLastPrediction" name="check-button" switch>
      Mostrar Solo Ultima Predicción 
    </b-form-checkbox>

                </b-button-toolbar>
              </div>
            </template>
          </ag-grid-table>
        </b-col>
      </b-row>

    </section>
  </template>
          
          <script>
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  
  
  
  import useApiServices from '@/services/useApiServices.js';
  
  import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  
  
  import axios from "@axios";
  var qs = require('qs');
  
  import
  {
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
  
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  
    BOverlay,
    BSpinner
  
  
  } from "bootstrap-vue";
  
  
  
  export default {
    components: {
      AgGridTable,
      BRow,
      BCol,
      BButtonToolbar,
      BButtonGroup,
      BButton,
      BInputGroup,
      BFormInput,
      BFormGroup,
      BFormTextarea,
      BFormCheckbox,
  
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
  
  
      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
  
      BOverlay,
      BSpinner,
  
      BrunaForm,
      BrunaElementLoader,
  
  
  
  
    },
  
    methods: {

      showLastPredictionChange(checked)
      {
        console.log({checked});

        let query = qs.stringify({
          show_last_prediction: checked
        })

        this.historicoOptimizationsConfig = useApiServices.historicoPredicciones.table00Config  + "?" + query
        this.historicoOptimizationsData = useApiServices.historicoPredicciones.table00Data  + "?" + query     

        this.$refs['grid-historico-runs'].refreshRows();

      },
  
      findFieldByName(fields, name)
      {
  
        return fields.find(field => field.id == name)
  
      },
  
  
  
      debugChange(value)
      {
  
        console.log(value)
  
        let decoded = JSON.parse(value)
  
  
  
  
  
      },
  
  
      roundValue(value, decimales = 2)
      {
        let tmp = Math.pow(10, decimales)
  
        return Math.round(value * tmp) / tmp
      },
  
  
      /*
      actionReceived(action_name, data, params)
      {
        console.log("actionReceived", { action_name, data, params })
      },
  */
  
  
  
  
  
      compareRuns()
      {
  
        this.$router.push({ name: 'compare-predictions', query: { ids: this.selectedRows.map(run => run.id) } })
      },
  
  
  
      /*
      onGridReady(gridApi, gridColumnApi, params)
      {
  
        this.gridApi = gridApi
  
        console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
  
  
  
      },
  */
      onSelectionChanged(selectedRows)
      {
        console.log(selectedRows)
  
        this.selectedRows = selectedRows
  
        this.disableCompareRuns = (selectedRows.length < 2)
        this.disableRellenar = !(selectedRows.length == 1)
  
      },

      /*
      onGetRowsLoaded(gridApi, gridColumnApi, params)
      {
        console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })
  
        this.tableLoaded = true;
  
  
      },
  */
  
      accionDescargar(data)
      {
        console.log(data)
  
        if (data.data.output_data == null)
        {
          return
        }
  
        let optimization_id = data.data.id;
  
        let query = qs.stringify({ optimization_id: optimization_id })
  
        useApiServices.getDownloadFile(useApiServices.detalleOptimizacion.detalleOptimizacionDownloadExcel + "?" + query)
  
  
      },

  
      accionVerDetalles(data)
      {
  
        console.log(data)
  
  
        let id = data.data.id;
  
  
  
        this.$router.push({ name: 'canias-detalle-turno', query: { id: id } })
  
      },      
  
  
    },
  
    mounted()
    {
  
  
  
  
  
    },
  
  
  
  
    created()
    {
  
  
    },
  
  
    data()
    {
  
      let debug = (this.$route.query.debug == '1')
  
      let query = qs.stringify({
        show_last_prediction: true
      })

  
      return {
  

 
        actions: [
          { name: "Ver Detalles", icon: "EyeIcon", action: this.accionVerDetalles },
  
        ],


        debug: debug,
  
        showOverlay: false,
        overlayMessage: "Cargando...",
        tableLoaded: false,
  
  
  
        rowClassRules: {
  
          'escenario-base': (params) =>
          {
            if (params.data == undefined)
            {
              return false;
            }
  
            return params.data.default === 1;
          }
  
        },
  
        gridApi: null,
  
        itemsData: [],
        //pinnedTopRowData: [],
  
        //historicoOptimizationsConfig: useApiServices.historicoOptimizationsConfig,
        //historicoOptimizationsData: useApiServices.historicoOptimizationsData,


        historicoOptimizationsConfig: useApiServices.historicoPredicciones.table00Config  + "?" + query,
        historicoOptimizationsData: useApiServices.historicoPredicciones.table00Data  + "?" + query,
  
        disableCompareRuns: true,
        disableRellenar: true,
        selectedRows: [],

        showLastPrediction: true,
  
  
  
  
  
  
      };
    },
  };
</script>
<style lang="scss" scoped>
  span {
    font-size: 14px;
  }
</style>
        
        
<style lang="scss" >
  @import "@core/scss/vue/libs/vue-slider.scss";
  
  .escenario-base {
    background-color: #00bcd4 !important;
  }
  </style>
        
        <style lang="scss" scoped>
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
          