<template>
    <section>
      <b-row>
        <b-col>
          <bruna-element-loader :dataUrl="matrizRendimientoPorFundoChart00" loadingStrategy="ALWAYS">
          </bruna-element-loader>
        </b-col>
        <b-col>
          <bruna-element-loader :dataUrl="rendimientoPorEquipoChart00" loadingStrategy="ALWAYS">
          </bruna-element-loader>
        </b-col>
  
  
      </b-row>

      <b-row>

          <b-col>
            <bruna-element-loader :dataUrl="rendimientoPorEquipoChart01" loadingStrategy="ALWAYS">
          </bruna-element-loader>


          </b-col>
         


      </b-row>
  
      <b-row>
        <b-col sm="12">
          <ag-grid-table
            ref="grid-historico-runs"
            :debug="true"
            :configUrl="tabla00Config"
            :dataUrl="tabla00Data"
            rowSelection="multiple"
            :actions="actions"
          >
            <template v-slot:actions>
            </template>
          </ag-grid-table>
        </b-col>
      </b-row>
  
  
    </section>
  </template>
            
            <script>
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  
  
  import Stat from '@/views/clients/base/components/Stat.vue'
  
  import useApiServices from '@/services/useApiServices.js';
  
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  
  
  
  import axios from "@axios";
  var qs = require('qs');
  
  import
  {
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  
    BOverlay,
    BSpinner
  
  
  } from "bootstrap-vue";
  
  
  
  export default {
    components: {
      AgGridTable,
      BRow,
      BCol,
      BButtonToolbar,
      BButtonGroup,
      BButton,
      BInputGroup,
      BFormInput,
      BFormGroup,
      BFormTextarea,
  
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
  
  
      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
  
      Stat,
  
      BOverlay,
      BSpinner,
  
      BrunaElementLoader
  
  
  
  
  
    },
  
    methods: {
  
      accionVerDetalles(data)
      {
  
        console.log(data)
  
  
        let id = data.data.id;
  
  
  
        this.$router.push({ name: 'canias-detalle-turno', query: { id: id } })
  
      },
  
  
  
  
  
    },
  
  
  
    watch: {
      // whenever question changes, this function will run
      fnd_name: {
        handler(newValue, oldValue)
        {
  
          let query = qs.stringify({
            fnd_name: newValue,
            mdf_name: this.mdf_name,
          })
  
          this.matrizRendimientoPorFundoChart00 = useApiServices.historicoPredicciones.matrizRendimientoPorFundoChart00 + "?" + query
  
          this.rendimientoPorEquipoChart00 =  useApiServices.historicoPredicciones.rendimientoPorEquipoChart00 + "?" + query
          this.rendimientoPorEquipoChart01 =  useApiServices.historicoPredicciones.rendimientoPorEquipoChart01 + "?" + query
  
          this.tabla00Config = useApiServices.historicoPredicciones.table00Config + "?" + query
          this.tabla00Data = useApiServices.historicoPredicciones.table00Data + "?" + query
  
  
        },
  
      },
  
      mdf_name: {
        handler(newValue, oldValue)
        {
  
          let query = qs.stringify({
            fnd_name: this.fnd_name,
            mdf_name: newValue,
          })
  
          this.matrizRendimientoPorFundoChart00 = useApiServices.historicoPredicciones.matrizRendimientoPorFundoChart00 + "?" + query
  
          this.rendimientoPorEquipoChart00 =  useApiServices.historicoPredicciones.rendimientoPorEquipoChart00 + "?" + query
          this.rendimientoPorEquipoChart01 =  useApiServices.historicoPredicciones.rendimientoPorEquipoChart01 + "?" + query
  
          this.tabla00Config = useApiServices.historicoPredicciones.table00Config + "?" + query
          this.tabla00Data = useApiServices.historicoPredicciones.table00Data + "?" + query
  
  
  
        },
  
      }
  
  
    },
  
  
  
  
    props: {
  
      mdf_name: {
        type: String,
        required: true,
        default: null
      },
  
  
      fnd_name: {
        type: String,
        required: true,
        default: null
      },
  
  
    },
  
    mounted()
    {
  
  
    },
  
  
  
  
    created()
    {
  
  
    },
  
  
    data()
    {
  
      let query = qs.stringify({
        fnd_name: this.fnd_name,
        mdf_name: this.mdf_name,
      })
  
  
      return {
  
        showOverlay: false,
        overlayMessage: "Cargando...",
  
        matrizRendimientoPorFundoChart00: useApiServices.historicoPredicciones.matrizRendimientoPorFundoChart00 + "?" + query,
        rendimientoPorEquipoChart00: useApiServices.historicoPredicciones.rendimientoPorEquipoChart00 + "?" + query,
        rendimientoPorEquipoChart01: useApiServices.historicoPredicciones.rendimientoPorEquipoChart01 + "?" + query,
  
        tabla00Config: useApiServices.historicoPredicciones.table00Config + "?" + query,
        tabla00Data: useApiServices.historicoPredicciones.table00Data + "?" + query,
  
        actions: [
          { name: "Ver Detalles", icon: "DownloadCloudIcon", action: this.accionVerDetalles },
  
        ],
  
  
  
      };
    },
  };
            </script>
            <style lang="scss" scoped>
  span {
    font-size: 14px;
  }
  </style>
          
          
          <style lang="scss" >
  @import "@core/scss/vue/libs/vue-slider.scss";
  
  .escenario-base {
    background-color: #00bcd4 !important;
  }
  </style>
          
          <style lang="scss" scoped>
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
            